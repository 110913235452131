#limitedtop {
  position: relative;
  z-index: 10;

  * {
    line-height: 1;
  }

  .page-content {
    max-width: 100vw;
    margin: 0;
    padding: 0;
  }

  .sqs-layout > .sqs-row {
    margin: 0;
  }

  .sqs-block {
    padding: 0;

    @include mq() {
      padding: 0 !important;
    }
  }

  .container {
    &__limitededition {
      position: absolute;
      top: 59.2vw;
      right: 0;
      @include mq() {
        top: 111vw;
      }
    }
  }

  .limitededition {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    &__image {
      width: pxToRem(85);

      @include mq() {
        width: pxToRem(86);
      }
    }

    p {
      margin: 0;
      background-color: #ef6b00;
      text-align: right;
    }

    &__text-top {
      margin-top: pxToRem(4) !important;
      padding: pxToRem(2) pxToRem(4) pxToRem(2) pxToRem(4);
      @include fontSizePxToRem(19);
      @extend .font__midashi-go-mb31-pr6n;

      @include mq() {
        margin-top: 2px !important;
        padding-top: pxToRem(3);
      }
    }

    &__text-middle {
      margin-top: pxToRem(7) !important;
      padding: pxToRem(1) pxToRem(4) pxToRem(2) pxToRem(4);
      @include fontSizePxToRem(20);
      @extend .font__midashi-go-mb31-pr6n;
    }

    &__text-bottom {
      margin-top: pxToRem(3) !important;
      padding: pxToRem(2) pxToRem(4);
      @include fontSizePxToRem(20);
      @extend .font__midashi-go-mb31-pr6n;

      @include mq() {
        padding-top: pxToRem(1);
      }
    }
  }

}