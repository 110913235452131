#modal {
  .page-content {
    padding: 0;
  }
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    transform: translateY(100vh);
    transition: transform 10ms 300ms;
    z-index: 1000;
    &.open {
      transform: translateY(0);
      transition: transform 10ms;
      .modal__backdrop {
        background-color: rgba(0, 0, 0, 0.8);
        transition: background-color 300ms 10ms;
      }
      .modal__content {
        transform: translateY(0);
        transition: transform 300ms;
      }
    }
  }
}

#modal {
  .modal {
    &__backdrop {
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 300ms;
    }
  }
}

#modal {
  .modal {
    &__content {
      max-width: pxToRem(315);
      width: 100%;
      overflow-y: scroll;
      color: #000000;
      background-color: #f2f0f0;
      transform: translateY(100vh);
      transition: transform 300ms;
    }
    &__close {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      @include fontSizePxToRem(20);
      line-height: 1;
      cursor: pointer;
      transform: translate(pxToRem(-16), pxToRem(12));
    }
  }
}

#modal {
  .content {
    padding: pxToRem(30) pxToRem(27) pxToRem(24) pxToRem(27);
  }
}

#modal {
  .content {
    .setA {
      padding-bottom: pxToRem(16);
      text-align: center;
      &__image {
        display: flex;
        flex-direction: row;
        img {
          display: block;
          width: 23%;
          height: pxToRem(45);
          margin-right: pxToRem(6);
        }
      }
      &__text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: pxToRem(7);
        @extend .font__gothic-bbb-pr6n;
        @include fontSizePxToRem(14);
        line-height: 1;
      }
      &__purchaseButton {
        button {
          width: 100%;
          margin-top: pxToRem(8);
          padding: pxToRem(10) 0;
          border: none;
          background-color: #ecc025;
          border-radius: pxToRem(5);
        }
        .reserve {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          &__image {
            width: pxToRem(19);
            height: pxToRem(19);
          }
          &__text {
            width: 76%;
            margin: 0;
            @extend .font__midashi-go-mb31-pr6n;
            @include fontSizePxToRem(16);
            line-height: 1;
            color: #000000;
            transform: translateX(pxToRem(5));
          }
        }
      }
    }
  }
}

#modal {
  .content {
    .setA-option {
      padding-bottom: pxToRem(11);
      text-align: center;
      &__image {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        img {
          display: block;
          width: 23%;
          height: pxToRem(45);
        }
      }
      &__text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: pxToRem(7);
        @extend .font__gothic-bbb-pr6n;
        @include fontSizePxToRem(14);
        line-height: 1;
      }
      &__purchaseButton {
        button {
          width: 100%;
          margin-top: pxToRem(8);
          padding: pxToRem(10) 0;
          border: none;
          background-color: #ecc025;
          border-radius: pxToRem(5);
        }
        .reserve {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          &__image {
            width: pxToRem(19);
            height: pxToRem(19);
          }
          &__text {
            width: 76%;
            margin: 0;
            @extend .font__midashi-go-mb31-pr6n;
            @include fontSizePxToRem(16);
            line-height: 1;
            color: #000000;
            transform: translateX(pxToRem(5));
          }
          &__text--small {
            @include fontSizePxToRem(12);
          }
        }
      }
      &__info {
        margin: 0;
        @extend .font__gothic-bbb-pr6n;
        @include fontSizePxToRem(10);
        text-align: center;
      }
    }
  }
}

#modal {
  .content {
    .setB {
      padding-bottom: pxToRem(10);
      text-align: center;
      &__image {
        display: flex;
        flex-direction: row;
        img {
          display: block;
          width: 23%;
          height: pxToRem(45);
          margin-right: pxToRem(6);
        }
      }
      &__text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: pxToRem(8);
        @extend .font__gothic-bbb-pr6n;
        @include fontSizePxToRem(14);
        line-height: 1;
      }
      &__purchaseButton {
        button {
          width: 100%;
          margin-top: pxToRem(8);
          padding: pxToRem(10) 0;
          border: none;
          background-color: #ecc025;
          border-radius: pxToRem(5);
        }
        .reserve {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          &__image {
            width: pxToRem(19);
            height: pxToRem(19);
          }
          &__text {
            width: 76%;
            margin: 0;
            @extend .font__midashi-go-mb31-pr6n;
            @include fontSizePxToRem(16);
            line-height: 1;
            color: #000000;
            transform: translateX(pxToRem(5));
          }
        }
      }
    }
  }
}

#modal {
  .content {
    .text {
      margin: 0 auto;
      @extend .font__gothic-bbb-pr6n;
      @include fontSizePxToRem(12);
      text-align: center;
      &__date {
        margin: 0;
        margin-top: pxToRem(7);
        line-height: 1;
      }
      &__free {
        margin: 0;
        margin-top: pxToRem(5);
        line-height: 1;
      }
    }
  }
}

#modal {
  @include mq() {
    .sqs-layout .sqs-row .sqs-block:first-child {
      padding-top: 0 !important;
    }
    .sqs-layout .sqs-row .sqs-block:last-child {
      padding-bottom: 0 !important;
    }
    .sqs-layout .sqs-row .sqs-block {
      padding: 0;
    }
  }
}