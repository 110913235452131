#floatingfooter.show {
  opacity: 1;
  transition: opacity 1000ms 600ms;
}

#floatingfooter {
  * {
    line-height: 1;
  }

  width: 100vw;
  height: pxToRem(69);
  height: constant(69px + env(safe-area-inset-bottom));
  height: calc(69px + env(safe-area-inset-bottom));
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #28615a;
  opacity: 0;
  z-index: 999;

  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .page-content {
    padding: 0;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: pxToRem(375);
    margin: 0 auto;
    @include mq(sm) {
      max-width: pxToRem(320);
    }
  }
}

#floatingfooter {
  .container {
    .memorialset {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      color: #ffffff;
      &__amount {
        margin: 0;
        @extend .font__midashi-go-mb31-pr6n;
        @include fontSizePxToRem(14);
        letter-spacing: pxToRem(0.2);
        @include mq(sm) {
          @include fontSizePxToRem(12);
        }
      }
      &__text {
        margin: 0;
        margin-top: pxToRem(6.5);
        margin-bottom: pxToRem(5);
        @extend .font__midashi-go-mb31-pr6n;
        @include fontSizePxToRem(19);
        @include mq() {
          margin-top: pxToRem(7);
          margin-bottom: pxToRem(2);
        }
        @include mq(sm) {
          @include fontSizePxToRem(16);
        }
        &--small {
          margin-left: pxToRem(5);
          @include fontSizePxToRem(10);
          @include mq(sm) {
            @include fontSizePxToRem(8);
          }
        }
      }
    }
    .button {
      display: flex;
      align-items: center;
      padding: pxToRem(7.5) pxToRem(17.5) pxToRem(7) pxToRem(18);
      background-color: #e5c932;
      border-radius: pxToRem(22.5);
      border: none;
      cursor: pointer;
      @include mq() {
        padding-right: pxToRem(15);
        padding-left: pxToRem(15);
      }
      @include mq(sm) {
        padding-right: pxToRem(10);
        padding-left: pxToRem(10);
      }
      &__icon {
        width: pxToRem(22);
        height: pxToRem(22);
      }
      &__text {
        margin-left: pxToRem(8);
        color: #28615a;
        &--reserve {
          margin: 0;
          @extend .font__midashi-go-mb31-pr6n;
          @include fontSizePxToRem(19);
          @include mq(sm) {
            @include fontSizePxToRem(16);
          }
        }
        &--date {
          margin: 0;
          margin-top: pxToRem(1);
          @extend .font__midashi-go-mb31-pr6n;
          @include fontSizePxToRem(9);
          text-align: center;
          letter-spacing: pxToRem(1.62);
          @include mq() {
            letter-spacing: 0;
          }
          @include mq(sm) {
            @include fontSizePxToRem(7);
          }
        }
      }
    }
  }
}