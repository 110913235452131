* {
  box-sizing: border-box;
  font-feature-settings: "palt";
}

body {
  padding-bottom: pxToRem(69);
  font-family: "YakuHanJP", Midashi Go MB31, sans-serif;
  font-style: normal;
  font-weight: 600;
}

#footer {
  @include mq() {
    .nav-wrapper {
      display: none;
    }
  }
}