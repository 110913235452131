@charset "UTF-8";
#header {
  display: none;
}

body {
  font-family: YakuHanJP, Gothic Medium BBB, sans-serif;
}

h1, h2, h3 {
  font-family: YakuHanJP, Midashi Go MB31, sans-serif;
}

h3 {
  text-transform: inherit;
}

blockquote {
  padding: 0;
  font-size: 0.875em;
  font-style: normal;
}

button {
  opacity: 1;
  transition: opacity 500ms;
}

button:hover, button:active, button:focus {
  opacity: 0.8;
}

@media screen and (max-width: 640px) {
  .Footer .sqs-gallery-design-grid-slide {
    width: 33.3333% !important;
  }
}

.font__midashi-go-mb31-pr6n, #top .limitednumber__amount, #top .limitednumber__text, #top .limitednumber__date, #setitem .detail__button, #floatingfooter .container .memorialset__amount, #floatingfooter .container .memorialset__text, #floatingfooter .container .button__text--reserve, #floatingfooter .container .button__text--date, #modal .content .setA__purchaseButton .reserve__text, #modal .content .setA-option__purchaseButton .reserve__text, #modal .content .setB__purchaseButton .reserve__text, #limitedtop .limitededition__text-top, #limitedtop .limitededition__text-middle, #limitedtop .limitededition__text-bottom, #limitedfloatingfooter .container .limited__amount, #limitedfloatingfooter .container .limited__text, #limitedfloatingfooter .container .button__text--reserve, #limitedfloatingfooter .container .button__text--date {
  font-family: "YakuHanJP", Midashi Go MB31, sans-serif;
  font-style: normal;
  font-weight: 600;
}

.font__gothic-bbb-pr6n, #modal .content .setA__text, #modal .content .setA-option__text, #modal .content .setA-option__info, #modal .content .setB__text, #modal .content .text {
  font-family: "YakuHanJP", Gothic Medium BBB, sans-serif;
  font-style: normal;
  font-weight: 400;
}

* {
  box-sizing: border-box;
  font-feature-settings: "palt";
}

body {
  padding-bottom: 4.3125rem;
  font-family: "YakuHanJP", Midashi Go MB31, sans-serif;
  font-style: normal;
  font-weight: 600;
}

@media screen and (max-width: 735px) {
  #footer .nav-wrapper {
    display: none;
  }
}

#top {
  position: relative;
  z-index: 10;
}

#top * {
  line-height: 1;
}

#top .page-content {
  max-width: 100vw;
  margin: 0;
  padding: 0;
}

#top .sqs-layout > .sqs-row {
  margin: 0;
}

#top .sqs-block {
  padding: 0;
}

@media screen and (max-width: 735px) {
  #top .sqs-block {
    padding: 0 !important;
  }
}

#top .container__memorialset {
  position: absolute;
  top: 55.2vw;
  left: 0;
}

@media screen and (max-width: 735px) {
  #top .container__memorialset {
    top: 112.5vw;
  }
}

#top .memorialset__image {
  width: 4.0625rem;
  transform: translateX(0.0625rem);
}

@media screen and (max-width: 735px) {
  #top .memorialset__image {
    width: 4.25rem;
  }
}

#top .memorialset__text {
  display: flex;
  flex-direction: column;
  margin-top: 0.3125rem;
}

@media screen and (max-width: 735px) {
  #top .memorialset__text {
    margin-top: 0.25rem;
  }
}

#top .memorialset__text span {
  padding: 0.125rem 0 0.0625rem 0.125rem;
  font-size: 20px;
  font-size: 1.25rem;
  background-color: #ffffff;
  color: #ef6b00;
}

@media screen and (max-width: 735px) {
  #top .memorialset__text span {
    padding-right: 0.125rem;
  }
}

#top .memorialset__text--first {
  letter-spacing: 0.125rem;
}

#top .memorialset__text--tsuki {
  background-color: transparent !important;
}

@media screen and (max-width: 735px) {
  #top .memorialset__text--tsuki {
    font-size: 0.875rem !important;
  }
}

#top .memorialset__text--second {
  margin-top: 0.15625rem;
  letter-spacing: 0.05625rem;
}

#top .container__limitednumber {
  position: absolute;
  top: 56.6vw;
  right: 0;
}

@media screen and (max-width: 735px) {
  #top .container__limitednumber {
    top: 129vw;
  }
}

#top .limitednumber {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

#top .limitednumber__image {
  width: 5rem;
}

@media screen and (max-width: 735px) {
  #top .limitednumber__image {
    width: 5.3125rem;
  }
}

#top .limitednumber p {
  margin: 0;
  background-color: #ef6b00;
  text-align: right;
}

#top .limitednumber__amount {
  margin-top: 0.0625rem !important;
  padding: 0.125rem 0.25rem 0.25rem 0.25rem;
  font-size: 23px;
  font-size: 1.4375rem;
}

@media screen and (max-width: 735px) {
  #top .limitednumber__amount {
    margin-top: 0 !important;
  }
}

#top .limitednumber__text {
  margin-top: 0.0625rem !important;
  padding: 0.125rem 0.25rem 0.1875rem 0.25rem;
  font-size: 23px;
  font-size: 1.4375rem;
}

#top .limitednumber__date {
  margin-top: 0.1875rem !important;
  padding: 0.25rem;
  font-size: 12px;
  font-size: 0.75rem;
}

#point1 .page-content {
  max-width: 100vw;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin: 0;
}

#point1 .sqs-layout > .sqs-row {
  margin: 0;
}

#point1 .sqs-block {
  padding: 0;
}

@media screen and (max-width: 735px) {
  #point1 .sqs-block {
    padding: 0 !important;
  }
}

#point2 .page-content {
  max-width: 100vw;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin: 0;
}

#point2 .sqs-layout > .sqs-row {
  margin: 0;
}

#point2 .sqs-block {
  padding: 0;
}

@media screen and (max-width: 735px) {
  #point2 .sqs-block {
    padding: 0 !important;
  }
}

#setitem .purchase__button--A {
  border: none;
  background-color: transparent;
}

#setitem .purchase__button--A-option {
  border: none;
  background-color: transparent;
}

#setitem .detail__button {
  display: block;
  margin: 0 0.4375rem;
  padding: 1rem 0;
  font-size: 14px;
  font-size: 0.875rem;
  background-color: #e5c932;
  color: #28615a;
  text-align: center;
  text-decoration: none;
  border-radius: 0.3125rem;
}

#setitem .detail__button::before {
  content: '▼';
  width: 0.625rem;
  height: 0.625rem;
}

#setb .purchase__button--B button {
  border: none;
  background-color: transparent;
}

#message {
  margin-top: -9vw;
}

@media screen and (max-width: 735px) {
  #message {
    margin-top: -19vw;
    padding-top: 21vw;
  }
}

#floatingfooter.show {
  opacity: 1;
  transition: opacity 1000ms 600ms;
}

#floatingfooter {
  width: 100vw;
  height: 4.3125rem;
  height: constant(69pxenv(safe-area-inset-bottom));
  height: calc(69px + env(safe-area-inset-bottom));
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #28615a;
  opacity: 0;
  z-index: 999;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

#floatingfooter * {
  line-height: 1;
}

#floatingfooter .page-content {
  padding: 0;
}

#floatingfooter .container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 23.4375rem;
  margin: 0 auto;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container {
    max-width: 20rem;
  }
}

#floatingfooter .container .memorialset {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #ffffff;
}

#floatingfooter .container .memorialset__amount {
  margin: 0;
  font-size: 14px;
  font-size: 0.875rem;
  letter-spacing: 0.0125rem;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .memorialset__amount {
    font-size: 12px;
    font-size: 0.75rem;
  }
}

#floatingfooter .container .memorialset__text {
  margin: 0;
  margin-top: 0.40625rem;
  margin-bottom: 0.3125rem;
  font-size: 19px;
  font-size: 1.1875rem;
}

@media screen and (max-width: 735px) {
  #floatingfooter .container .memorialset__text {
    margin-top: 0.4375rem;
    margin-bottom: 0.125rem;
  }
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .memorialset__text {
    font-size: 16px;
    font-size: 1rem;
  }
}

#floatingfooter .container .memorialset__text--small {
  margin-left: 0.3125rem;
  font-size: 10px;
  font-size: 0.625rem;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .memorialset__text--small {
    font-size: 8px;
    font-size: 0.5rem;
  }
}

#floatingfooter .container .button {
  display: flex;
  align-items: center;
  padding: 0.46875rem 1.09375rem 0.4375rem 1.125rem;
  background-color: #e5c932;
  border-radius: 1.40625rem;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 735px) {
  #floatingfooter .container .button {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .button {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

#floatingfooter .container .button__icon {
  width: 1.375rem;
  height: 1.375rem;
}

#floatingfooter .container .button__text {
  margin-left: 0.5rem;
  color: #28615a;
}

#floatingfooter .container .button__text--reserve {
  margin: 0;
  font-size: 19px;
  font-size: 1.1875rem;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .button__text--reserve {
    font-size: 16px;
    font-size: 1rem;
  }
}

#floatingfooter .container .button__text--date {
  margin: 0;
  margin-top: 0.0625rem;
  font-size: 9px;
  font-size: 0.5625rem;
  text-align: center;
  letter-spacing: 0.10125rem;
}

@media screen and (max-width: 735px) {
  #floatingfooter .container .button__text--date {
    letter-spacing: 0;
  }
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .button__text--date {
    font-size: 7px;
    font-size: 0.4375rem;
  }
}

#modal .page-content {
  padding: 0;
}

#modal .modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transform: translateY(100vh);
  transition: transform 10ms 300ms;
  z-index: 1000;
}

#modal .modal.open {
  transform: translateY(0);
  transition: transform 10ms;
}

#modal .modal.open .modal__backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  transition: background-color 300ms 10ms;
}

#modal .modal.open .modal__content {
  transform: translateY(0);
  transition: transform 300ms;
}

#modal .modal__backdrop {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 300ms;
}

#modal .modal__content {
  max-width: 19.6875rem;
  width: 100%;
  overflow-y: scroll;
  color: #000000;
  background-color: #f2f0f0;
  transform: translateY(100vh);
  transition: transform 300ms;
}

#modal .modal__close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1;
  cursor: pointer;
  transform: translate(-1rem, 0.75rem);
}

#modal .content {
  padding: 1.875rem 1.6875rem 1.5rem 1.6875rem;
}

#modal .content .setA {
  padding-bottom: 1rem;
  text-align: center;
}

#modal .content .setA__image {
  display: flex;
  flex-direction: row;
}

#modal .content .setA__image img {
  display: block;
  width: 23%;
  height: 2.8125rem;
  margin-right: 0.375rem;
}

#modal .content .setA__text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 0.4375rem;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1;
}

#modal .content .setA__purchaseButton button {
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.625rem 0;
  border: none;
  background-color: #ecc025;
  border-radius: 0.3125rem;
}

#modal .content .setA__purchaseButton .reserve {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#modal .content .setA__purchaseButton .reserve__image {
  width: 1.1875rem;
  height: 1.1875rem;
}

#modal .content .setA__purchaseButton .reserve__text {
  width: 76%;
  margin: 0;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1;
  color: #000000;
  transform: translateX(0.3125rem);
}

#modal .content .setA-option {
  padding-bottom: 0.6875rem;
  text-align: center;
}

#modal .content .setA-option__image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#modal .content .setA-option__image img {
  display: block;
  width: 23%;
  height: 2.8125rem;
}

#modal .content .setA-option__text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 0.4375rem;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1;
}

#modal .content .setA-option__purchaseButton button {
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.625rem 0;
  border: none;
  background-color: #ecc025;
  border-radius: 0.3125rem;
}

#modal .content .setA-option__purchaseButton .reserve {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#modal .content .setA-option__purchaseButton .reserve__image {
  width: 1.1875rem;
  height: 1.1875rem;
}

#modal .content .setA-option__purchaseButton .reserve__text {
  width: 76%;
  margin: 0;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1;
  color: #000000;
  transform: translateX(0.3125rem);
}

#modal .content .setA-option__purchaseButton .reserve__text--small {
  font-size: 12px;
  font-size: 0.75rem;
}

#modal .content .setA-option__info {
  margin: 0;
  font-size: 10px;
  font-size: 0.625rem;
  text-align: center;
}

#modal .content .setB {
  padding-bottom: 0.625rem;
  text-align: center;
}

#modal .content .setB__image {
  display: flex;
  flex-direction: row;
}

#modal .content .setB__image img {
  display: block;
  width: 23%;
  height: 2.8125rem;
  margin-right: 0.375rem;
}

#modal .content .setB__text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 0.5rem;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1;
}

#modal .content .setB__purchaseButton button {
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.625rem 0;
  border: none;
  background-color: #ecc025;
  border-radius: 0.3125rem;
}

#modal .content .setB__purchaseButton .reserve {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#modal .content .setB__purchaseButton .reserve__image {
  width: 1.1875rem;
  height: 1.1875rem;
}

#modal .content .setB__purchaseButton .reserve__text {
  width: 76%;
  margin: 0;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1;
  color: #000000;
  transform: translateX(0.3125rem);
}

#modal .content .text {
  margin: 0 auto;
  font-size: 12px;
  font-size: 0.75rem;
  text-align: center;
}

#modal .content .text__date {
  margin: 0;
  margin-top: 0.4375rem;
  line-height: 1;
}

#modal .content .text__free {
  margin: 0;
  margin-top: 0.3125rem;
  line-height: 1;
}

@media screen and (max-width: 735px) {
  #modal .sqs-layout .sqs-row .sqs-block:first-child {
    padding-top: 0 !important;
  }
  #modal .sqs-layout .sqs-row .sqs-block:last-child {
    padding-bottom: 0 !important;
  }
  #modal .sqs-layout .sqs-row .sqs-block {
    padding: 0;
  }
}

#limitedtop {
  position: relative;
  z-index: 10;
}

#limitedtop * {
  line-height: 1;
}

#limitedtop .page-content {
  max-width: 100vw;
  margin: 0;
  padding: 0;
}

#limitedtop .sqs-layout > .sqs-row {
  margin: 0;
}

#limitedtop .sqs-block {
  padding: 0;
}

@media screen and (max-width: 735px) {
  #limitedtop .sqs-block {
    padding: 0 !important;
  }
}

#limitedtop .container__limitededition {
  position: absolute;
  top: 59.2vw;
  right: 0;
}

@media screen and (max-width: 735px) {
  #limitedtop .container__limitededition {
    top: 111vw;
  }
}

#limitedtop .limitededition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

#limitedtop .limitededition__image {
  width: 5.3125rem;
}

@media screen and (max-width: 735px) {
  #limitedtop .limitededition__image {
    width: 5.375rem;
  }
}

#limitedtop .limitededition p {
  margin: 0;
  background-color: #ef6b00;
  text-align: right;
}

#limitedtop .limitededition__text-top {
  margin-top: 0.25rem !important;
  padding: 0.125rem 0.25rem 0.125rem 0.25rem;
  font-size: 19px;
  font-size: 1.1875rem;
}

@media screen and (max-width: 735px) {
  #limitedtop .limitededition__text-top {
    margin-top: 2px !important;
    padding-top: 0.1875rem;
  }
}

#limitedtop .limitededition__text-middle {
  margin-top: 0.4375rem !important;
  padding: 0.0625rem 0.25rem 0.125rem 0.25rem;
  font-size: 20px;
  font-size: 1.25rem;
}

#limitedtop .limitededition__text-bottom {
  margin-top: 0.1875rem !important;
  padding: 0.125rem 0.25rem;
  font-size: 20px;
  font-size: 1.25rem;
}

@media screen and (max-width: 735px) {
  #limitedtop .limitededition__text-bottom {
    padding-top: 0.0625rem;
  }
}

#limitedmessage {
  margin-top: -9vw;
}

@media screen and (max-width: 735px) {
  #limitedmessage {
    margin-top: -19vw;
    padding-top: 21vw;
  }
}

#limiteditem .purchase__button button {
  border: none;
  background-color: transparent;
}

#limitedfloatingfooter.show {
  opacity: 1;
  transition: opacity 1000ms 600ms;
}

#limitedfloatingfooter {
  width: 100vw;
  height: 4.3125rem;
  height: constant(69pxenv(safe-area-inset-bottom));
  height: calc(69px + env(safe-area-inset-bottom));
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #28615a;
  opacity: 0;
  z-index: 999;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

#limitedfloatingfooter * {
  line-height: 1;
}

#limitedfloatingfooter .page-content {
  padding: 0;
}

#limitedfloatingfooter .container {
  display: flex;
  justify-content: space-around;
  width: 23.4375rem;
  margin: 0 auto;
}

@media screen and (max-width: 374px) {
  #limitedfloatingfooter .container {
    max-width: 20rem;
  }
}

#limitedfloatingfooter .container .limited {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #ffffff;
}

#limitedfloatingfooter .container .limited__amount {
  margin: 0;
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 0.0125rem;
}

#limitedfloatingfooter .container .limited__text {
  margin: 0;
  margin-top: 0.40625rem;
  margin-bottom: 0.3125rem;
  font-size: 19px;
  font-size: 1.1875rem;
}

@media screen and (max-width: 735px) {
  #limitedfloatingfooter .container .limited__text {
    margin-top: 0.4375rem;
    margin-bottom: 0.125rem;
  }
}

@media screen and (max-width: 374px) {
  #limitedfloatingfooter .container .limited__text {
    font-size: 16px;
    font-size: 1rem;
  }
}

#limitedfloatingfooter .container .limited__text--small {
  margin-left: 0.3125rem;
  font-size: 10px;
  font-size: 0.625rem;
}

@media screen and (max-width: 374px) {
  #limitedfloatingfooter .container .limited__text--small {
    font-size: 8px;
    font-size: 0.5rem;
  }
}

#limitedfloatingfooter .container .button {
  display: flex;
  align-items: center;
  padding: 0.46875rem 1.09375rem 0.4375rem 1.125rem;
  background-color: #e5c932;
  border: none;
  border-radius: 1.40625rem;
  cursor: pointer;
}

@media screen and (max-width: 735px) {
  #limitedfloatingfooter .container .button {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (max-width: 374px) {
  #limitedfloatingfooter .container .button {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

#limitedfloatingfooter .container .button__icon {
  width: 1.375rem;
  height: 1.375rem;
}

#limitedfloatingfooter .container .button__text {
  margin-left: 0.5rem;
  color: #28615a;
}

#limitedfloatingfooter .container .button__text--reserve {
  margin: 0;
  font-size: 19px;
  font-size: 1.1875rem;
}

@media screen and (max-width: 374px) {
  #limitedfloatingfooter .container .button__text--reserve {
    font-size: 16px;
    font-size: 1rem;
  }
}

#limitedfloatingfooter .container .button__text--date {
  margin: 0;
  margin-top: 0.0625rem;
  font-size: 9px;
  font-size: 0.5625rem;
  text-align: center;
  letter-spacing: 0;
}

@media screen and (max-width: 374px) {
  #limitedfloatingfooter .container .button__text--date {
    font-size: 7px;
    font-size: 0.4375rem;
  }
}
