#top {
  position: relative;
  z-index: 10;

  * {
    line-height: 1;
  }

  .page-content {
    max-width: 100vw;
    margin: 0;
    padding: 0;
  }

  .sqs-layout > .sqs-row {
    margin: 0;
  }

  .sqs-block {
    padding: 0;

    @include mq() {
      padding: 0 !important;
    }
  }

  .container {
    &__memorialset {
      position: absolute;
      top: 55.2vw;
      left: 0;
      @include mq() {
        top: 112.5vw;
      }
    }
  }

  .memorialset {
    &__image {
      width: pxToRem(65);
      transform: translateX(pxToRem(1));

      @include mq() {
        width: pxToRem(68);
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      margin-top: pxToRem(5);

      @include mq() {
        margin-top: pxToRem(4);
      }

      span {
        padding: pxToRem(2) 0 pxToRem(1) pxToRem(2);
        @include fontSizePxToRem(20);
        background-color: #ffffff;
        color: #ef6b00;
        
        @include mq() {
          padding-right: pxToRem(2);
        }
      }

      &--first {
        letter-spacing: pxToRem(2);
      }

      &--tsuki {
        background-color: transparent !important;

        @include mq() {
          font-size: pxToRem(14) !important;
        }
      }

      &--second {
        margin-top: pxToRem(2.5);
        letter-spacing: pxToRem(0.9);
      }
    }
  }

  .container {
    &__limitednumber {
      position: absolute;
      top: 56.6vw;
      right: 0;

      @include mq() {
        top: 129vw;
      }
    }
  }

  .limitednumber {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    &__image {
      width: pxToRem(80);

      @include mq() {
        width: pxToRem(85);
      }
    }

    p {
      margin: 0;
      background-color: #ef6b00;
      text-align: right;
    }

    &__amount {
      margin-top: pxToRem(1) !important;
      padding: pxToRem(2) pxToRem(4) pxToRem(4) pxToRem(4);
      @include fontSizePxToRem(23);
      @extend .font__midashi-go-mb31-pr6n;

      @include mq() {
        margin-top: 0 !important;
      }
    }

    &__text {
      margin-top: pxToRem(1) !important;
      padding: pxToRem(2) pxToRem(4) pxToRem(3) pxToRem(4);
      @include fontSizePxToRem(23);
      @extend .font__midashi-go-mb31-pr6n;
    }

    &__date {
      margin-top: pxToRem(3) !important;
      padding: pxToRem(4);
      @include fontSizePxToRem(12);
      @extend .font__midashi-go-mb31-pr6n;
    }
  }

}