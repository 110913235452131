.font {
  &__midashi-go-mb31-pr6n {
    font-family: "YakuHanJP", Midashi Go MB31, sans-serif;
    font-style: normal;
    font-weight: 600;
  }

  &__gothic-bbb-pr6n {
    font-family: "YakuHanJP", Gothic Medium BBB, sans-serif;
    font-style: normal;
    font-weight: 400;
  }
}