#setitem {
  .purchase {
    &__button {
      &--A {
        border: none;
        background-color: transparent;
      }
      &--A-option {
        border: none;
        background-color: transparent;
      }
    }
  }

  .detail {
    &__button {
      display: block;
      margin: 0 pxToRem(7);
      padding: pxToRem(16) 0;
      @include fontSizePxToRem(14);
      @extend .font__midashi-go-mb31-pr6n;
      background-color: #e5c932;
      color: #28615a;
      text-align: center;
      text-decoration: none;
      border-radius: pxToRem(5);

      &::before {
        content: '▼';
        width: pxToRem(10);
        height: pxToRem(10);
      }
    }
  }
}

#setb {
  .purchase {
    &__button {
      &--B {
        button {
          border: none;
          background-color: transparent;
        }
      }
    }
  }
}